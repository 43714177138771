import React from "react";
import {
  Table,
  Box,
  Avatar,
  Flex,
  Text,
  LoadingOverlay,
  Card,
} from "@mantine/core";
import {
  ChevronDown as IconPriceDown,
  ChevronUp as IconPriceUp,
  Star as IconWatchlist,
} from "lucide-react";
import { observer } from "mobx-react";
import { Listing } from "models";
import classes from "./styles/DataTable.module.css";

interface DataTableProps {
  data: Listing[];
  isLoading: boolean;
  isError: boolean;
  favoriteAssetIds: string[];
  handleFavoriteClick: (id: string) => void;
}

const ChangeBadge: React.FC<{ change: number }> = ({ change }) => {
  const isPositive: boolean = change > 0;
  return (
    <Text
      color={isPositive ? `green` : `red`}
      fw={`bold`}
      style={{ display: "flex", alignItems: "center" }}
    >
      {isPositive ? <IconPriceUp size={16} /> : <IconPriceDown size={16} />}
      {Math.abs(change).toFixed(2)}%
    </Text>
  );
};

const formatNumber = (value: number, fractionDigits: number = 0) => {
  return value.toLocaleString(undefined, {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  });
};

export const DataTable: React.FC<DataTableProps> = observer(
  ({ data, isLoading, isError, favoriteAssetIds, handleFavoriteClick }) => {
    return (
      <Box pos="relative">
        <LoadingOverlay
          visible={isLoading}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />
        {isError && (
          <Card style={{ minHeight: "30vh" }} mt={`xl`} withBorder>
            <Flex justify="center" align="center">
              <Text color="red" size="lg">
                Unable to load latest pricing, please try again later.
              </Text>
            </Flex>
          </Card>
        )}
        {!isError && (
          <Table.ScrollContainer minWidth="sm" type="native">
            <Table>
              <Table.Thead className={classes.header}>
                <Table.Tr>
                  <Table.Th>Name</Table.Th>
                  <Table.Th>Price</Table.Th>
                  <Table.Th>Market Cap</Table.Th>
                  <Table.Th>Volume</Table.Th>
                  <Table.Th>Circulating Supply</Table.Th>
                  <Table.Th>Change % (1h)</Table.Th>
                  <Table.Th>Change % (24h)</Table.Th>
                  <Table.Th></Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {data.map((listing: Listing) => (
                  <Table.Tr key={listing.id} className={classes.row}>
                    <Table.Td>
                      <Flex gap={`sm`} align={`center`}>
                        <Avatar
                          src={listing.icon}
                          alt={listing.name}
                          size={30}
                        />
                        <Text fw={600}>{listing.name}</Text>
                        <Text size="sm">{listing.symbol}</Text>
                      </Flex>
                    </Table.Td>
                    <Table.Td>{formatNumber(listing.currentPrice, 2)}</Table.Td>
                    <Table.Td>{formatNumber(listing.marketCap)}</Table.Td>
                    <Table.Td>{formatNumber(listing.volume24h)}</Table.Td>
                    <Table.Td>
                      {listing.circulatingSupply.toLocaleString()}
                    </Table.Td>
                    <Table.Td>
                      <ChangeBadge change={listing.change1h} />
                    </Table.Td>
                    <Table.Td>
                      <ChangeBadge change={listing.change24h} />
                    </Table.Td>
                    <Table.Td>
                      <IconWatchlist
                        size={18}
                        onClick={() => handleFavoriteClick(listing.id)}
                        color="orange"
                        fill={
                          favoriteAssetIds.includes(listing.id)
                            ? "orange"
                            : "none"
                        }
                      />
                    </Table.Td>
                  </Table.Tr>
                ))}
              </Table.Tbody>
            </Table>
          </Table.ScrollContainer>
        )}
      </Box>
    );
  }
);
