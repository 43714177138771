import React, { useEffect, useState } from "react";
import { Box, Container } from "@mantine/core";
import { DataTable, Header, Search } from "components";
import { Display, Listing } from "models";
import { useQuery } from "react-query";
import { listingStore } from "store";
import { fetchListings } from "service";
import { observer } from "mobx-react";

const Home: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [listings, setListings] = useState<Listing[]>([]);
  const [display, setDisplay] = useState<Display>(Display.Main);
  const favoriteIds = listingStore.favorites.map((asset) => asset.id);

  const handleSearchTermChange = (value: string) => setSearchTerm(value);

  useEffect(() => {
    const dataToDisplay =
      display === Display.Watchlist
        ? listingStore.favorites
        : listingStore.listings;

    if (searchTerm) {
      setListings(
        dataToDisplay.filter((listing) =>
          listing.name.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    } else {
      setListings(dataToDisplay);
    }
  }, [display, searchTerm]);

  const handleWatchlistClick = () => {
    setDisplay((prev) =>
      prev === Display.Main ? Display.Watchlist : Display.Main
    );
  };

  const handleFavoriteClick = (id: string) => {
    const isFavorite = listingStore.favorites.some((asset) => asset.id === id);
    if (isFavorite) {
      listingStore.removeFavorite(id);
    } else {
      const assetToAdd = listingStore.listings.find((asset) => asset.id === id);
      if (assetToAdd) {
        listingStore.addFavorite(assetToAdd);
      }
    }
  };

  const { data, isError, isFetching } = useQuery({
    queryKey: ["latestListings"],
    queryFn: fetchListings,
    staleTime: 1000 * 60,
    refetchInterval: 1000 * 60,
    enabled: true,
  });

  useEffect(() => {
    if (data) {
      listingStore.setListings(data);
      setListings(data);
    }
  }, [data]);

  return (
    <Container size="xl" my={`4em`}>
      <Box my={`xl`}>
        <Header
          handleSearchTermChange={handleSearchTermChange}
          handleWatchlistClick={handleWatchlistClick}
          currentView={display}
        />
      </Box>
      <Box my={`xl`}>
        <DataTable
          data={listings}
          isLoading={isFetching}
          isError={isError}
          favoriteAssetIds={favoriteIds}
          handleFavoriteClick={handleFavoriteClick}
        />
      </Box>
    </Container>
  );
};

export default observer(Home);
