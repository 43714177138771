import React from "react";
import { Search } from "./index";
import { Button, Grid } from "@mantine/core";
import {
  Star as IconWatchlist,
  PieChart as IconPortfolio,
  Home as IconHome,
} from "lucide-react";
import { Display } from "models";

interface HeaderProps {
  handleSearchTermChange: (value: string) => void;
  handleWatchlistClick: () => void;
  currentView: Display;
}

export const Header: React.FC<HeaderProps> = ({
  handleSearchTermChange,
  handleWatchlistClick,
  currentView,
}) => {
  return (
    <Grid>
      <Grid.Col span={{ sm: 12, md: 8 }}>
        <Search onSearchChange={handleSearchTermChange} />
      </Grid.Col>
      <Grid.Col span={{ sm: 12, md: 2 }}>
        <Button
          variant="outline"
          w={`100%`}
          color="orange"
          leftSection={
            currentView === Display.Main ? (
              <IconWatchlist size={18} />
            ) : (
              <IconHome size={18} />
            )
          }
          onClick={() => handleWatchlistClick()}
        >
          {currentView === Display.Main ? "Watchlist" : "Back to home"}
        </Button>
      </Grid.Col>
      <Grid.Col span={{ sm: 12, md: 2 }}>
        <Button
          variant="outline"
          color="orange"
          w={`100%`}
          leftSection={<IconPortfolio size={18} />}
        >
          Portfolio
        </Button>
      </Grid.Col>
    </Grid>
  );
};
