import { Listing } from 'models';
import { makeAutoObservable } from 'mobx';
import { makePersistable, clearPersistedStore } from 'mobx-persist-store';

class ListingsStore {
  listings: Listing[] = [];
  favorites: Listing[] = [];

  constructor() {
    makeAutoObservable(this);
    this.init();
  }

  private async init() {
    makePersistable(this, {
      name: 'ListingsStore',
      properties: ['listings', 'favorites'],
      storage: localStorage,
    });
  }

  public setListings(listings: Listing[]) {
    this.listings = listings;
  }

  public addListing(listing: Listing) {
    this.listings.push(listing);
  }

  public addFavorite(listing: Listing) {
    this.favorites.push(listing);
  }

  public removeFavorite(id: string) {
    this.favorites = this.favorites.filter((fav) => fav.id !== id);
  }

  public clearListings() {
    this.listings = [];
    this.favorites = [];
    clearPersistedStore(this);
  }
}

const listingStore = new ListingsStore();
export default listingStore;