import React from "react";
import Home from "Home";
import { createTheme, MantineProvider } from "@mantine/core";
import { QueryClientProvider, QueryClient } from "react-query";
import "@mantine/core/styles.css";

const theme = createTheme({
  fontFamily: "Inter, Segoe UI, Robot, sans-serif",
  primaryColor: "orange",
});

const queryClient = new QueryClient();

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <MantineProvider theme={theme}>
        <Home />
      </MantineProvider>
    </QueryClientProvider>
  );
};

export default App;
