import { Listing } from "models";
import axios from 'axios';
import config from "config";

export const getInitialSampleListings = () => {
  return Array.from({ length: 10 }, (): Listing => ({
    id: "-",
    name: "-",
    symbol: "-",
    currentPrice: 0,
    marketCap: 0,
    volume24h: 0,
    circulatingSupply: 0,
    change1h: 0,
    change24h: 0,
    icon: "-"
  }));
};

export const fetchListings = async (): Promise<Listing[]> => {
  try {
    const response = await axios.get(`${config.api}/prices`, {
      params: {
        start: 1,
        limit: 10,
        convert: 'USD',
        sort: 'market_cap',
        sort_dir: 'desc'
      },
      headers: {
        'accept': 'application/json'
      }
    });

    if (response.data && response.data.data) {
      return response.data.data.map((item: any) => ({
        id: item.id.toString(),
        name: item.name,
        symbol: item.symbol,
        currentPrice: item.quote.USD.price,
        marketCap: item.quote.USD.market_cap,
        volume24h: item.quote.USD.volume_24h,
        circulatingSupply: item.circulating_supply,
        change1h: item.quote.USD.percent_change_1h,
        change24h: item.quote.USD.percent_change_24h,
        icon: `${config.iconUrl}/${item.id}.png`
      }));
    }

    return [];
  } catch (error) {
    throw new Error(`Failed to fetch listings: ${error}`);
  }
}