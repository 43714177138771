import * as React from "react";
import { Input } from "@mantine/core";
import { Search as IconSearch } from "lucide-react";

interface SearchProps {
  onSearchChange: (searchTerm: string) => void;
}

export const Search: React.FC<SearchProps> = ({ onSearchChange }) => {
  return (
    <Input
      placeholder="Enter a name to search"
      leftSection={<IconSearch size={16} />}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
        onSearchChange(event.currentTarget.value)
      }
    />
  );
};
